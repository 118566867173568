import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { Copy, H1, H2 } from '../../components/atoms/Typography';
import { CopyColoredSpan } from '../../components/atoms/Typography/Copy';
import { VideoPlayer } from '../../components/atoms/VideoPlayer';

const About = () => {
  return (
    <>
      <H1>About us</H1>
      <Copy marginBottom={32}>
        Welcome to JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, your all-in-one platform for streamlining your job
        search process. With our comprehensive suite of features, tracking your job applications and maintaining a
        well-organized job search has never been easier. From collaborating directly with your recruiter to effortlessly
        navigating each stage of your journey, JobsBoard<CopyColoredSpan>.io</CopyColoredSpan> is here to embrace
        efficiency and elevate your career prospects.
      </Copy>

      <Copy marginBottom={32}>
        Efficiency is at the core of JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, and our tasks manager ensures that
        you can effortlessly manage every aspect of your job search. Seamlessly track your progress, set reminders, and
        stay organized as you work towards your career goals. With our intuitive interface, you'll never miss a deadline
        or forget an important task again.
      </Copy>

      <Copy marginBottom={32}>
        Crafting a standout resume is essential in today's competitive job market, and JobsBoard
        <CopyColoredSpan>.io</CopyColoredSpan> is here to help. Our AI assistant swiftly tailors your resume for each
        application, ensuring that you stand out from the crowd and open doors to a plethora of career opportunities.
        With personalized guidance and expert suggestions, you can confidently showcase your skills and experiences to
        potential employers.
      </Copy>

      <Copy marginBottom={32}>
        In addition to crafting impressive resumes, JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>'s AI assistant can
        also help personalize your cover letters and important emails. Aligning with your job search preferences and the
        specific requirements of each role, our AI ensures that your communications are tailored to perfection,
        increasing your chances of landing interviews and securing offers.
      </Copy>

      <Copy marginBottom={32}>
        At JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, we understand the importance of staying organized and
        prepared throughout your job search journey. That's why our platform allows you to capture every detail, from
        contact information for recruiters and interviewers to crucial job information and interview dates. With premium
        features like daily and weekly email reminders, Google Maps integration for easy exploration of job locations,
        and instant progress snapshots, JobsBoard<CopyColoredSpan>.io</CopyColoredSpan> is your ultimate companion for
        success in the job market.
      </Copy>

      <VideoPlayer url="https://youtu.be/1yvGXc_bP-A" width="1280px" height="720px"></VideoPlayer>

      <Spacer y={62} />

      <H2>Our mission</H2>
      <Copy marginBottom={32}>
        At JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, our mission is to empower job seekers with the tools and
        resources they need to navigate the job market with confidence and ease. We are committed to revolutionizing the
        job search experience by providing a comprehensive platform that streamlines every aspect of the process. Our
        mission is to foster efficiency, organization, and collaboration, ensuring that every individual can unlock
        their full potential and achieve their career aspirations.
      </Copy>

      <Copy marginBottom={32}>
        Through innovative features like our AI Resume Builder, AI-Powered Cover Letter generator, and seamless job
        application tracking, we aim to simplify the complexities of the job search journey. Our mission is to make it
        effortless for job seekers to craft standout application materials, collaborate directly with recruiters, and
        stay organized throughout the process.
      </Copy>

      <Copy marginBottom={32}>
        We believe in the power of technology to enhance the job search experience, which is why our platform harnesses
        the latest advancements in AI and data integration. By leveraging these tools, we enable job seekers to tailor
        their applications, communicate effectively with potential employers, and stay on top of every detail of their
        job search journey.
      </Copy>

      <Copy marginBottom={32}>
        Ultimately, our mission is to level the playing field in the job market, providing all individuals with access
        to the resources and support they need to succeed. Whether you're a recent graduate, a seasoned professional, or
        transitioning careers, JobsBoard<CopyColoredSpan>.io</CopyColoredSpan> is here to support you every step of the
        way on your path to success.
      </Copy>

      <Copy marginBottom={32} styleLevel={1}>
        <b>The JobsBoard.io Team.</b>
      </Copy>
    </>
  );
};

export default About;
